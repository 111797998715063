import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Section from "../components/section"

import Testimonials from "../components/testimonials"
import ContactBar from "../components/contactbar"

const IndexPage = ({
  data: {
    wpgraphql: { themeHeadlessSettings },
  },
}) => {
  return (
    <Layout>
      <Section className="section-hero container">
        <h1>{themeHeadlessSettings.heroHeading}</h1>
        <p>{themeHeadlessSettings.heroSubheading}</p>
        <Link to={"/contact"}>How Can We Help?</Link>
      </Section>
      <ContactBar></ContactBar>
      <Section className="section-one"></Section>
      <Section className="section-two"></Section>
      <Section className="section-threec"></Section>
      <Section class="testimonials">
        <Testimonials />
      </Section>
      <Section className="final-section"></Section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    wpgraphql {
      themeHeadlessSettings {
        heroHeading
        heroSubheading
        phoneNumber
        emailAddress

        sectionOneAccent
        sectionOneContent
        sectionOneHeading
        sectionOneImage
        sectionOneLink
        sectionOneLinkText
        sectionOneSubheading

        sectionTwoAccent
        sectionTwoContent
        sectionTwoHeading
        sectionTwoImage
        sectionTwoLink
        sectionTwoLinkText
        sectionTwoSubheading

        threecContentOne
        threecContentThree
        threecContentTwo

        sectionFinalAccent
        sectionFinalContent
        sectionFinalImage
        sectionFinalHeading
        sectionFinalLink
        sectionFinalLinkText
        sectionFinalSubheading
      }
    }
  }
`
