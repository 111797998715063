import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

var settings = {
  dots: false,
  infinite: true,
  speed: 5,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const Testimonials = () => (
  <StaticQuery
    query={graphql`
      query {
        wpgraphql {
          testimonials {
            edges {
              node {
                title
                content
                id
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <div className="container">
          <h2 style={{ textAlign: "center" }}>Testimonials</h2>
          <Slider {...settings}>
            {data.wpgraphql.testimonials.edges.map(({ node }) => (
              <div key={node.id}>
                <figure>
                  <blockquote
                    dangerouslySetInnerHTML={{
                      __html: node.content,
                    }}
                  />
                  <footer>
                    --{}
                    <cite className="testimonial-source">{node.title}</cite>
                  </footer>
                </figure>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )}
  />
)

export default Testimonials
